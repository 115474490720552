html,
body {
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    color: var(--mainLight);
    background: var(--darkModeColor);
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

@font-face {
    font-family: myMuseo;
    src: url(/museo_sans_ssv/Museo700/MuseoSans_700.otf);
}

:root {
    --yellowColor: #ffff00;
    --blueViolet: #7070db;
    --darkBlueViolet: #2e2eb8;
    --mainLight: #f2f2f2;
    --darkModeColor: #1a0000;
    --darkModeColorTransparent: rgb(26, 0, 0, 0.85);
    --darkGreyColor: #262626;
    --maroonColor: #800000;
    --maroonDarkColor: #4d0000;
    --magentaColor: #ff3385;
    --redEbay: #ff0000;
    --blueEbay: #5c5cd6;
    --orangeEbay: #ff8000;
    --greenEbay: #00cc44;
    --whiteColor: #ffffff;
    --lightBrown: rgba(255, 218, 179, 0.2)
}

h1 {
    font-family: 'myMuseo', cursive;
    font-size: 1rem;
    text-align: center;
    padding-bottom: .5rem;
}

h2 {
    font-family: 'myMuseo', cursive;
    font-size: 1rem;
    text-align: center;
    padding-bottom: .3rem;
}

.sectionOrArticleTB {
    padding-top: 0;
    padding-bottom: 0;
}

.sectionOrArticleB {
    padding-top: 0;
    padding-bottom: 0;
}

.sectionOrArticleT {
    padding-top: 0;
    padding-bottom: 0;
}

@media (min-width: 576px) {
    h1 {
        font-size: 1.3rem;
        padding-bottom: 0.5rem;
    }

    h2 {
        font-size: 1.1rem;
        padding-bottom: 0.3rem;
    }

    .sectionOrArticleTB {
        padding-top: .5rem;
        padding-bottom: .5rem;
    }

    .sectionOrArticleB {
        padding-top: 0;
        padding-bottom: .5rem;
    }

    .sectionOrArticleT {
        padding-top: .5rem;
        padding-bottom: 0;
    }
}

@media (min-width: 768px) {
    h1 {
        font-size: 1.6rem;
        padding-bottom: 1rem;
    }

    h2 {
        font-size: 1.3rem;
        padding-bottom: 0.8rem;
    }

    .sectionOrArticleTB {
        padding-top: 1rem;
        padding-bottom: .1rem;
    }

    .sectionOrArticleB {
        padding-top: 0;
        padding-bottom: 1rem;
    }

    .sectionOrArticleT {
        padding-top: 1rem;
        padding-bottom: 0;
    }
}

@media (min-width: 992px) {
    h1 {
        font-size: 1.9rem;
        padding-bottom: 1.5rem;
    }

    h1 {
        font-size: 1.6rem;
        padding-bottom: 0.7rem;
    }

    .sectionOrArticleTB {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .sectionOrArticleB {
        padding-top: 0;
        padding-bottom: 2rem;
    }

    .sectionOrArticleT {
        padding-top: 2rem;
        padding-bottom: 0;
    }
}

@media (min-width: 1200px) {
    h1 {
        font-size: 2.2rem;
        padding-bottom: 2rem;
    }

    h2 {
        font-size: 2rem;
        padding-bottom: 1.8rem;
    }

    .sectionOrArticleTB {
        padding-top: 3.5rem;
        padding-bottom: 3.5rem;
    }

    .sectionOrArticleB {
        padding-top: 0;
        padding-bottom: 3.5rem;
    }

    .sectionOrArticleT {
        padding-top: 3.5rem;
        padding-bottom: 0;
    }
}

@media (max-width: 778px) {
    p {
        font-size: .8rem;
    }
}

.darkMode {
    color: var(--mainLight);
    background: var(--darkModeColor);
}

.darkModeText {
    color: var(--mainLight);
    background: var(--darkModeColor);
    padding: .5rem;
}

.lightMode {
    color: var(--darkGreyColor);
    background: var(--mainLight);
}

.lightModeText {
    color: var(--darkGreyColor);
    background: var(--whiteColor);
    padding: .5rem;
}

.main {
    min-height: 100vh;
    height: 100%;
}

.caregoriesTitle {
    display: inline-block;
    padding: 1rem 2rem;
    background-color: var(--darkModeColorTransparent);
    color: var(--mainLight);
    margin: 0 auto;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    margin-bottom: 1rem;
}

.imgStripContainer {
    position: relative;
}

.imgStripText {
    position: absolute;
    top: 0;
    width: 100%;
    height: 2.1rem;
    display: block;
}
/*shared*/
a#basic-nav-dropdown.dropdown-toggle.nav-link {
    color: #f2f2f2;
}

.aaa a#basic-nav-dropdown.dropdown-toggle.nav-link {
    color: var(--yellowColor);
}

a#basic-nav-dropdown.dropdown-toggle.nav-link:hover {
    color: #ffff00;
}

.navbar-toggler {
    /* color: #f2f2f2!important; */
    border: 2px solid #f2f2f2 !important;
}

span.navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28242, 242, 242, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}


.imageConers {
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    margin-bottom: 1rem;
}

.cardImage {
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    margin-bottom: 1rem;
    object-fit: scale-down;
}
/* home page */

.homeBgImg {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-repeat: repeat;
    background-position: center;
    min-height: 100vh;
    height: 100%;
    /*background-image: url('/images/balls.gif');*/
}

/*single coin*/
.table {
    --bs-table-hover-color: var(--orangeEbay);
}

button#coin-tab-tab-itemDetails.nav-link, button#coin-tab-tab-specification.nav-link {
    color: var(--darkGreyColor);
    background: var(--whiteColor);
}

    button#coin-tab-tab-itemDetails.nav-link.active, button#coin-tab-tab-specification.nav-link.active {
        color: var(--orangeEbay);
        background: var(--darkModeColor);
    }
/*dropdown select by country */
button#dropdown-basic.dropdown-toggle.btn.btn-primary {
    background: var(--maroonColor);
    --bs-btn-color: #fff;
    --bs-btn-bg: var(--maroonColor);
    --bs-btn-border-color: var(--yellowColor);
    --bs-btn-hover-color: var(--yellowColor);
    --bs-btn-hover-bg: var(--maroonDarkColor);
    --bs-btn-hover-border-color: var(--maroonColor);
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: var(--yellowColor);
    --bs-btn-active-bg: var(--maroonDarkColor);
    --bs-btn-active-border-color: var(--maroonDarkColor);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: var(--yellowColor);
    --bs-btn-disabled-bg: var(--maroonDarkColor);
    --bs-btn-disabled-border-color: var(--maroonDarkColor);
}
.mcountry .dropdown-menu.show {
    overflow-y: auto;
    max-width:17rem;
    position: relative !important;
    transform: translate3d(0,0,10px) !important;
}

/* nft accordions*/
.trAccordion button.accordion-button.collapsed,
.trAccordion button.accordion-button {
    background: transparent;
    border-radius: 0 !important;
    border-bottom: 1px solid var(--mainLight);
}

    .trAccordion button.accordion-button.collapsed:focus,
    .trAccordion button.accordion-button:focus,
    .trAccordion button.accordion-button.collapsed:active,
    .trAccordion button.accordion-button:active {
        border: none;
        border-radius: 0;
        box-shadow: none;
        border-bottom: 1px solid var(--mainLight);
    }

.trAccordion.light button.accordion-button::after {
    background-image: url('/images/chevron-circle-down.png');
    background-size: 18px;
}

.trAccordion.light button.accordion-button.collapsed::after {
    background-image: url('/images/chevron-circle-down.png');
    background-size: 18px;
}

.trAccordion.dark button.accordion-button::after {
    background-image: url('/images/chevron-circle-down-light.png');
    background-size: 18px;
}

.trAccordion.dark button.accordion-button.collapsed::after {
    background-image: url('/images/chevron-circle-down-light.png');
    background-size: 18px;
}

.accordionDark {
    color: var(--mainLight);
}

.accordionLight {
    color: var(--darkGreyColor);
}
/*pagination  .page-item:active */

.page-item{
    color: var(--orangeEbay)!important;
}

.page-link.active, .active > .page-link {    
    background-color: var(--orangeEbay);
    border-color: var(--orangeEbay);
}
